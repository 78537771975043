import React, { useState } from "react";
import CommonBanner from "../../components/GlobalComponents/CommonBanner";
import FooterComponent from "../../components/FooterComponent";
import ServicesCard from "../../components/GlobalComponents/ServicesCard.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faHeadset, faUserTie, faUtensils } from '@fortawesome/free-solid-svg-icons';


const ServicesPage = () => {

    const [ServiceCardData, setServiceCardData] = useState([
        {
            title: "Master Chefs",
            name: 'Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam',
            font: <FontAwesomeIcon icon={faUserTie} />

        },
        {
            title: "Quality Food",
            name: 'Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam',
            font: <FontAwesomeIcon icon={faUtensils} />
        },
        {
            title: "Online Order",
            name: 'Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam',
            font: <FontAwesomeIcon icon={faCartPlus} />

        },
        {
            title: "24/7 Service",
            name: 'Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam',
            font: <FontAwesomeIcon icon={faHeadset} />

        },
    ]);


    return (
        <>
            <CommonBanner />
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h5 className="section-title ff-secondary text-center text-warning fw-normal">Our Services</h5>
                        <h1 className="mb-5">Explore Our Services</h1>
                    </div>
                    <div className="row g-4">
                        {
                            ServiceCardData.map((val, index) => {
                                return (
                                    <ServicesCard val={val} index={index} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <FooterComponent />
        </>
    )
};



export default ServicesPage