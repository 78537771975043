import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from "react-router-dom";
import { Container } from 'react-bootstrap';
import logo from '../../assets/images/gazebo-logo.jpg';



const HeaderComponent = ({ offset }) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [pathname, setPathname] = useState('')
	const location = useLocation();
	// console.log("currrent location", location.pathname);
	useEffect(() => {
		setPathname(location.pathname)
	}, [])



	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};

	return (
		<>
			<header className={pathname == "/" ? offset >= 350 ? "header-with-scroll" : "header-without-scroll" : offset >= 250 ? "header-with-scroll" : "header-without-scroll"}>
				<Container>
					<nav>
						<a className="logo">
							<img src={logo} alt="Restaurant Logo" />
							<span>Gazeebo</span>
						</a>
						<div className={`navigation-text ${menuOpen ? 'open' : ''}`}>
							{menuOpen && (
								<div className="close-button" onClick={toggleMenu}>
									<FontAwesomeIcon icon={faTimes} />
								</div>
							)}
							<Link to={'/'} >Home</Link>
							<Link to={'/menu'} >Menu</Link>
							<Link to={'/services'} >Our Services</Link>
							<Link to={'/about'} >About</Link>
							<Link to={'/table-booking'} >Booking</Link>
							<Link to={'/contact'} >Contact</Link>
						</div>
						<div className="hamburger" onClick={toggleMenu}>
							<FontAwesomeIcon icon={faBars} />
						</div>
					</nav>
				</Container>
			</header>

		</>
	)
};



export default HeaderComponent