import React from "react";
import WhatsappLogo from "../../assets/images/svg/social/whatsapp.svg"
import InstagramLogo from "../../assets/images/svg/social/instagram.svg"
import FaceBookLogo from "../../assets/images/svg/social/facebook.svg"
import LinkDinLogo from "../../assets/images/svg/social/linkedin.svg"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMapMarker, faPhone } from "@fortawesome/free-solid-svg-icons";


const FooterComponent = () => {





    return (
        <div className="container-fluid bg-dark text-light footer pt-5 mt-5 main-footer">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-3 col-md-6">
                        <h4 className="section-title ff-secondary text-start text-warning fw-normal mb-4">Company</h4>
                        <Link className="btn btn-link" to={'/about'}>About Us</Link>
                        <Link className="btn btn-link" to={'/contact'}>Contact Us</Link>
                        <Link className="btn btn-link" to={'/contact'}>Reservation</Link>
                        <a className="btn btn-link" href="#">Privacy Policy</a>
                        <a className="btn btn-link" href="#">Terms & Condition</a>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4 className="section-title ff-secondary text-start text-warning fw-normal mb-4">Contact</h4>
                        <p className="mb-2">
                            <FontAwesomeIcon icon={faMapMarker} className="me-3" />
                            Shop No 10 Gurukripa Apt Opp Mumbra Station, Thane, Maharashtra 400612
                        </p>
                        <p className="mb-2">
                            <FontAwesomeIcon icon={faPhone} className="me-3" />
                            +012 345 67890</p>
                        <p className="mb-2">
                            <FontAwesomeIcon icon={faEnvelope} className="me-3" />
                            info@example.com</p>
                        <div className="d-flex pt-2">
                            <a className="btn btn-outline-light btn-social" href="">
                                <img src={WhatsappLogo} alt="" className="social-svg-log" />
                            </a>
                            <a className="btn btn-outline-light btn-social" href="">
                                <img src={InstagramLogo} alt="" className="social-svg-log" />
                            </a>
                            <a className="btn btn-outline-light btn-social" href="">
                                <img src={FaceBookLogo} alt="" className="social-svg-log" />
                            </a>
                            <a className="btn btn-outline-light btn-social" href="">
                                <img src={LinkDinLogo} alt="" className="social-svg-log" />
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4 className="section-title ff-secondary text-start text-warning fw-normal mb-4">Opening</h4>
                        <h5 className="text-light fw-normal">Monday - Saturday</h5>
                        <p>10:00 AM - 11:00 PM</p>
                        <h5 className="text-light fw-normal">Sunday</h5>
                        <p>10AM - 08PM</p>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4 className="section-title ff-secondary text-start text-warning fw-normal mb-4">Newsletter</h4>
                        <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
                        <div className="position-relative mx-auto" style={{ maxWidth: 400 }}>
                            <input className="form-control border-warning w-100 py-3 ps-4 pe-5" type="text"
                                placeholder="Your email" />
                            <button type="button"
                                className="btn btn-outline-warning py-2 position-absolute top-0 end-0 mt-2 me-2">Send</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="copyright">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0 designed-by">
                            &copy; <a className="border-bottom" href="#">Gazebo Restaurant</a>, All Right Reserved.
                            Designed By <a className="border-bottom" href="http://www.crisfooddigiverse.com/" target="_blank">Crisfood Digiverse</a>
                        </div>
                        <div className="col-md-6 text-center text-md-end">
                            <div className="footer-menu">
                                <a href="#">Home</a>
                                <a href="#">Cookies</a>
                                <a href="#">Help</a>
                                <a href="#">FQAs</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default FooterComponent