import axios from "axios";
import React, { useState } from "react";
import Swal from 'sweetalert2';
import config from "../../config";



const BookingCardComponents = () => {
	const [customerName, setCustomerName] = useState("");
	const [mobileNaumber, setMobileNaumber] = useState("");
	const [totalPerson, setTotalPerson] = useState("");
	const [bookingDate, setBookingDate] = useState("");
	const [bookingTime, setBookingTime] = useState("10:00");

	/*-------------------------------------------------------------
										 Add Food Booking
		---------------------------------------------------------------*/
	const addFoodBooking = () => {
		if (!customerName.trim() || !mobileNaumber.trim() || !totalPerson.trim() || !bookingDate.trim() || !bookingTime.trim()) {
			Swal.fire('Please fill All Fields! ', '', 'warning');
			// setValidation({
			//   // BookingName: !BookingName.trim(),
			//   // BasePrice: !BasePrice.trim(),
			//   // discountPrice: !discountPrice.trim(),
			//   // // IncludingVeg: IncludingVeg != null,
			//   // Categoryimage: Bookingimage != null,
			//   // // categoryId: categoryId == null
			// })
		} else {
			const data =
			{
				"customer_name": customerName,
				"contact_number": mobileNaumber,
				"number_of_persons": totalPerson,
				"date": bookingDate,
				"time": bookingTime
			}
			axios.post(`${config.serverUrl}/reservation`, data)
				.then(function (response) {
					// console.log("catResppp=======>", response.data);
					Swal.fire('Reservation Request Sent Successfully! ', '', 'success');
					setBookingDate("");
					setMobileNaumber("");
					setCustomerName("");
					setTotalPerson("");
					setBookingTime("");
				})
				.catch(function (error) {
					console.log(error);
					Swal.fire(error.request.responseText, '', 'warning');
				});
		}
	}

	return (
		<>
			<div className="container-xxl py-5 px-0 wow fadeInUp" data-wow-delay="0.1s">
				<div className="row g-0">
					<div className="col-md-6">
						<div className="video">
							<a className="btn-play" data-bs-toggle="modal" href="#" data-bs-target="#videoModal">
								<span></span>
							</a>
						</div>
					</div>
					<div className="col-md-6 bg-dark d-flex align-items-center">
						<div className="p-5 wow fadeInUp" data-wow-delay="0.2s">
							<h5 className="section-title ff-secondary text-start text-warning fw-normal">Reservation</h5>
							<h1 className="text-white mb-4">Book A Table Online</h1>
							<div className="row g-3">
								<div className="col-md-6">
									<div className="form-floating">
										<input type="text" className="form-control" id="name" placeholder="Your Name"
											value={customerName} onChange={e => setCustomerName(e.target.value)}
										/>
										<label htmlFor="name">Your Name</label>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-floating">
										<input type="text" className="form-control" id="phone_number" placeholder="Phone Number"
											value={mobileNaumber} onChange={e => setMobileNaumber(e.target.value)}
										/>
										<label htmlFor="PhoneNumber">Phone Number</label>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-floating">
										<input type="text" className="form-control" id="total_person" placeholder="Total Person"
											value={totalPerson} onChange={e => setTotalPerson(e.target.value)}
										/>
										<label htmlFor="TotalPerson">Total Person</label>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-floating">
										<input type="time" className="form-control" id="Booking_Time" placeholder="Booking Time"
											value={bookingTime} onChange={e => setBookingTime(e.target.value)}
										/>
										<label htmlFor="BookingTime">Booking Time</label>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-floating">
										<input type="date" className="form-control" id="Booking_Date" placeholder="Booking Date"
											value={bookingDate} onChange={e => setBookingDate(e.target.value)}
										/>
										<label htmlFor="BookingTime">Booking Time</label>
									</div>
								</div>
								{/* <div className="col-12">
                                        <div className="form-floating">
                                            <textarea className="form-control" placeholder="Special Request" id="message" style={{ height: "100%" }}></textarea>
                                            <label htmlFor="message">Special Request</label>
                                        </div>
                                    </div> */}
								<div className="col-12">
									<button className="btn btn-outline-warning w-100 py-3" onClick={addFoodBooking}>Book Now</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <div className="modal fade" id="videoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog">
					<div className="modal-content rounded-0">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">Youtube Video</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<div className="ratio ratio-16x9">
								<iframe className="embed-responsive-item" src="" id="video" allowfullscreen allowscriptaccess="always"
									allow="autoplay"></iframe>
							</div>
						</div>
					</div>
				</div>
			</div> */}

		</>
	)
}

export default BookingCardComponents