import React from "react";




const ServicesCard = ({ val, index }) => {



    return (
        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s" key={index}>
            <div className="service-item rounded pt-3">
                <div className="p-4">
                    {/* <FontAwesomeIcon icon={faUserTie} className="fa-3x text-warning mb-4" /> */}
                    <div className="fa-3x text-warning mb-4" >
                        {val.font}
                    </div>
                    <h5>{val.title}</h5>
                    <p>{val.name}</p>
                </div>
            </div>
        </div>
    )
}
export default ServicesCard