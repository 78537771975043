import React from "react";
import CommonBanner from "../../components/GlobalComponents/CommonBanner";
import FooterComponent from "../../components/FooterComponent";
import BookingCardComponents from "../../components/BookingCardComponents";



const BookingPage = () => {




    return (
        <>
            <CommonBanner />
            <BookingCardComponents />
            <FooterComponent />
        </>
    )
}

export default BookingPage