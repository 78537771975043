import React from "react";
import { Card, Col } from "react-bootstrap";





const OffersCard = ({ value, index }) => {




    return (
        <Col key={index} md={3} className="mt-3">
            <Card className="offer-menu-card">
                <Card.Img variant="top" src={value.image} alt={value.title} className="offer-menu-card-image" />
                <Card.Body>
                    <Card.Title className="offer-menu-card-title">{value.title}</Card.Title>
                    <Card.Text className="offer-menu-card-description">{value.description}</Card.Text>
                    {/* <Card.Text className="menu-card-price">${value.price}</Card.Text> */}
                </Card.Body>
            </Card>
        </Col>
    )
};

export default OffersCard