import React, { useEffect, useState } from "react";
import CommonBanner from "../../components/GlobalComponents/CommonBanner";
import FooterComponent from "../../components/FooterComponent";
import { Container } from "react-bootstrap";
import TestImage from '../../assets/images/icon-image-not-found.jpg'
import NoDishImage from '../../assets/images/no-dish-image.png'
import config from "../../config";
import axios from "axios";


const MenuPage = () => {
	const [menuData, setmenuData] = useState([])

	const [activeCategory, setActiveCategory] = useState(menuData[0]);

	console.log("menuData[0]", menuData[0]);

	const handleClick = (categoryName) => {
		setActiveCategory(categoryName);
	};

	const getCategroy = async () => {
		try {
			const response = await axios.get(`${config.serverUrl}/product-category-with-products`);
			if (response.data.message == 'success') {
				console.log("=====>", response.data.data);
				setmenuData(response.data.data);
			}
		} catch (error) {
			// console.log("getCategroy_error=>", error.message);
		}
	}

	// useEffect

	useEffect(() => {
		getCategroy();
	}, [])


	return (
		<>
			<CommonBanner />
			<Container>
				<div className="menu-container">
					<div className="category-tabs">
						{menuData.map((category, index) => (
							<button
								key={index}
								className={activeCategory === category.name ? 'active' : ''}
								onClick={() => handleClick(category.name)}
							>
								{category.name}
							</button>
						))}
					</div>
				</div>
				{menuData.length == 0 ?
					<div className="container no-dish-image">
						<img src={NoDishImage} alt="Error" />
						<div className="no-food-items">No Food Items Available</div>
					</div> :
					<div className="menu-listing">
						{menuData.map((category, index) =>
							activeCategory === category.name ? (
								// (category.length == 0 ? <div> snmdfklmkl</div> :
								<div key={index} className="row category-menu">
									{
										category.products.map((dish, dishIndex) => (
											<div key={dishIndex} className="col-lg-2 col-md-2 mb-4 menu-card">
												<img src={dish.image ? dish.image : TestImage} alt={dish.name} className="dish-image" />
												<div className="dish-details">
													<h3 className="dish-name"><span className="title-text">Name :
													</span> {dish.name}</h3>
													<p className="dish-description"><span className="title-text">Title :
													</span> {dish.description}</p>
													<span> <span className="title-text">price :
													</span> <span className="base-price">₹ {dish.base_price}</span></span>
													<span className="dish-price">₹{dish.discount_price} </span>
												</div>
											</div>
										))}
								</div>
								// )
							) : null
						)}
					</div>
				}
			</Container>
			<FooterComponent />
		</>
	)
};



export default MenuPage