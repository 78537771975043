import React from "react";
import NoImageFound from '../../../assets/images/icon-image-not-found.jpg'
import VegIcon from '../../../assets/images/png/veg-icon.png'
import NonVegIcon from '../../../assets/images/png/non-veg-icon.png'



const FoodMenuCard = ({ index, dishName, dishDescription, basePrice, discountPrice, dishImage, isVeg }) => {


    return (
        <>
            <div className="col-lg-4 col-md-4 mb-4" key={index}>
                <div className="single-menu-item d-flex justify-content-between align-items-center h-100">
                    <div className="veg-icon">
                        <img src={isVeg === 1 ? VegIcon : NonVegIcon} alt="" />
                    </div>
                    <div className="menu-img">
                        <img src={dishImage ? dishImage : NoImageFound} alt="" />
                    </div>
                    <div className="menu-content">
                        <h5>{dishName}</h5>
                        <p>{dishDescription}</p>
                        <span>price : <span className="base-price">₹ {basePrice}</span></span>
                        <span> ₹{discountPrice} </span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FoodMenuCard