import React from "react";
import CommonBanner from "../../components/GlobalComponents/CommonBanner";
import FooterComponent from "../../components/FooterComponent";



const ContactUsPage = () => {


	return (
		<>
			<CommonBanner />
			<div class="container-xxl py-5">
				<div class="container">
					<div class="text-center wow fadeInUp" data-wow-delay="0.1s">
						<h5 class="section-title ff-secondary text-center text-warning fw-normal">Contact Us</h5>
						<h1 class="mb-5">Contact For Any Query</h1>
					</div>
					<div class="row g-4">
						<div class="col-12">
							<div class="row gy-4">
								<div class="col-md-4">
									<h5 class="section-title ff-secondary fw-normal text-start text-warning">Booking</h5>
									<p><i class="fa fa-envelope-open text-warning me-2"></i>book@example.com</p>
								</div>
								<div class="col-md-4">
									<h5 class="section-title ff-secondary fw-normal text-start text-warning">General</h5>
									<p><i class="fa fa-envelope-open text-warning me-2"></i>info@example.com</p>
								</div>
								<div class="col-md-4">
									<h5 class="section-title ff-secondary fw-normal text-start text-warning">Technical</h5>
									<p><i class="fa fa-envelope-open text-warning me-2"></i>tech@example.com</p>
								</div>
							</div>
						</div>
						<div class="col-md-6 wow fadeIn" data-wow-delay="0.1s">
							<iframe class="position-relative rounded w-100 h-100"
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.4010391375436!2d73.07480857423536!3d19.090054651533094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c14c278d550d%3A0xfac80acafd450504!2sGazebo%20Dhaba%20%26%20Family%20Restaurant!5e0!3m2!1sen!2sin!4v1689254635450!5m2!1sen!2sin"
								frameborder="0" style={{ minHeight: 350, border: 0 }} allowfullscreen="" aria-hidden="false"
								tabindex="0"></iframe>
						</div>
						<div class="col-md-6">
							<div class="wow fadeInUp" data-wow-delay="0.2s">
								<form>
									<div class="row g-3">
										<div class="col-md-6">
											<div class="form-floating">
												<input type="text" class="form-control" id="name" placeholder="Your Name" />
												<label for="name">Your Name</label>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-floating">
												<input type="email" class="form-control" id="email" placeholder="Your Email" />
												<label for="email">Your Email</label>
											</div>
										</div>
										<div class="col-12">
											<div class="form-floating">
												<input type="text" class="form-control" id="subject" placeholder="Subject" />
												<label for="subject">Subject</label>
											</div>
										</div>
										<div class="col-12">
											<div class="form-floating">
												<textarea class="form-control" placeholder="Leave a message here" id="message" style={{ height: 150 }}></textarea>
												<label for="message">Message</label>
											</div>
										</div>
										<div class="col-12">
											<button class="btn btn-outline-warning w-100 py-3" type="submit">Send Message</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FooterComponent />

		</>
	)
}

export default ContactUsPage