import React from "react";
import AboutUsComponent from "../../components/AboutUsComponent";
import FooterComponent from "../../components/FooterComponent";
import CommonBanner from "../../components/GlobalComponents/CommonBanner";



const AboutUsPage = () => {


    return (
        <>
            <CommonBanner />
            <AboutUsComponent />
            <FooterComponent />
        </>
    )
};



export default AboutUsPage