import React, { useEffect, useState } from "react";
import HomeScreenBanner from "../../components/HomeScreen/HomeScreenBanner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faHeadset, faUserTie, faUtensils } from '@fortawesome/free-solid-svg-icons';
import ServicesCard from "../../components/GlobalComponents/ServicesCard.js";
import AboutUsComponent from "../../components/AboutUsComponent";
import { Container, Row } from "react-bootstrap";
import FoodMenuCard from "../../components/GlobalComponents/FoodMenuCard";
import FooterComponent from "../../components/FooterComponent";
import NoDishImage from '../../assets/images/no-dish-image.png'
import config from "../../config";
import axios from "axios";
import OffersCard from "../../components/OffersCard";



const HomePage = ({ scrollDemo }) => {


	const [FoodMenuCardData, setFoodMenuCardData] = useState([]);
	const [showAll, setShowAll] = useState(false);
	const [ServiceCardData, setServiceCardData] = useState([
		{
			title: "Master Chefs",
			name: 'Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam',
			font: <FontAwesomeIcon icon={faUserTie} />

		},
		{
			title: "Quality Food",
			name: 'Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam',
			font: <FontAwesomeIcon icon={faUtensils} />
		},
		{
			title: "Online Order",
			name: 'Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam',
			font: <FontAwesomeIcon icon={faCartPlus} />

		},
		{
			title: "24/7 Service",
			name: 'Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam',
			font: <FontAwesomeIcon icon={faHeadset} />

		},
	]);

	const getFoodCategroy = async () => {
		try {
			const response = await axios.get(`${config.serverUrl}/products`);
			console.log("FoodCategroy=====>>>", response.data.data);
			setFoodMenuCardData(response.data.data);
		} catch (error) {
			// console.log("e=>", error?.message);
		}
	}

	// useEffect

	useEffect(() => {
		getFoodCategroy();
	}, [])

	const handleViewMore = () => {
		setShowAll(true);
	};
	const handleViewLess = () => {
		setShowAll(false);
	};

	const renderedData = showAll ? FoodMenuCardData : FoodMenuCardData.slice(0, 6);


	const [activeIndex, setActiveIndex] = useState(0);
	const [offer, setOffer] = useState([]);

	const getFoodOffers = async () => {
		try {
			const response = await axios.get(`${config.serverUrl}/offers`);
			console.log("FoodCategroy=====>>>", response.data.data);
			setOffer(response.data.data);
		} catch (error) {
			// console.log("e=>", error?.message);
		}
	}

	// useEffect

	useEffect(() => {
		getFoodOffers();
	}, [])


	return (
		<>
			{/* <div ref={scrollDemo}></div> */}
			<HomeScreenBanner />
			<Container>
				<h3 className="text-center mb-4 text-warning">Today Offers</h3>
				{offer.length === 0 ?
					<div className="container no-dish-image">
						<img src={NoDishImage} alt="Error" />
						<div className="no-food-items">No Food Items Available</div>
					</div> :
					<Row className="mt-4">
						{
							offer.map((value, index) => {
								return (
									<OffersCard value={value} index={index} />
								)
							})
						}
					</Row>}
			</Container>
			<div className="container-xxl py-5">
				<h3 className="text-center mb-4 text-warning">Our Services</h3>
				<div className="container">
					<div className="row g-4">
						{
							ServiceCardData.map((val, index) => {
								return (
									<ServicesCard val={val} index={index} />
								)
							})
						}
					</div>
				</div>
			</div>
			<AboutUsComponent />
			<Container>
				<h3 className="text-center mb-4 text-warning">Our Hot Dishes</h3>
				{renderedData.length === 0 ?
					<div className="container no-dish-image">
						<img src={NoDishImage} alt="Error" />
						<div className="no-food-items">No Food Items Available</div>
					</div>
					:
					<div className="row">
						{
							renderedData.map((value, index) => {
								return (
									<FoodMenuCard
										index={index}
										dishName={value.name}
										dishDescription={value.description}
										basePrice={value.base_price}
										discountPrice={value.discount_price}
										dishImage={value.category_image}
										isVeg={value.is_veg}
									/>
								)
							})

						}
					</div>}
				<div className="text-center ">
					{FoodMenuCardData.length > 6 && !showAll && (
						<button className="btn btn-outline-warning py-sm-3 px-sm-5 me-3 animated slideInLeft" onClick={handleViewMore}>View More</button>
					)}
					{showAll && (
						<button onClick={handleViewLess} className="btn btn-outline-warning py-sm-3 px-sm-5 me-3 animated slideInLeft">
							View Less
						</button>
					)}
				</div>
			</Container>
			<FooterComponent />
		</>
	)
};



export default HomePage